import { createAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  NetworkAccount,
  NetworkProject,
  BusinessAccountNetworkService,
  DeleteResponse,
} from "../../generated/openapi";
import { logRejectedThunk } from "../../sentry";
import { RootState } from "../../store";
import { returnWithErrorWrap } from "../../store/error";
import {
  markSearchResultRemoved,
  updateSearchResult,
} from "../business/search/businessSearchSlice";
import { createInitialState, error, pending } from "../../store/reducer";
import { resetStore } from "../../store/slice";

/**
 * Check if type is string or NetworkAccount (generally, to check if it's an ID or a full account).
 * @param account NetworkAccount object or ID.
 * @returns if given value is a NetworkAccount instance.
 */
export const isNetworkAccount = (account: string | NetworkAccount): account is NetworkAccount =>
  Boolean((account as NetworkAccount)?.id || (account as NetworkAccount)?.name);

export const createPayee = createAsyncThunk<NetworkAccount, { data: NetworkAccount; id: string }>(
  "business/payee/create",
  async ({ data, id }, thunk) =>
    returnWithErrorWrap(async () => {
      const account = await BusinessAccountNetworkService.addNetworkAccount(data, id);
      thunk.dispatch(updateSearchResult(account));
      return account;
    }, thunk)
);

export const updateNetworkAccount = createAsyncThunk<
  NetworkAccount,
  { data: NetworkAccount; id: string }
>("business/payee/update", async ({ data, id }, thunk) =>
  returnWithErrorWrap(
    async () => await BusinessAccountNetworkService.updateNetworkAccount(data, id, data.id),
    thunk
  )
);

export const updateNetworkAccountLocal = createAsyncThunk<NetworkAccount, NetworkAccount, {}>(
  "business/network/updateLocal",
  (arg, thunk) => arg
);

export const removeNetworkAccount = createAsyncThunk<
  DeleteResponse,
  { requestor_id: string; network_id: string }
>("business/payee/delete", async ({ requestor_id, network_id }, thunk) =>
  returnWithErrorWrap(async () => {
    const response = await BusinessAccountNetworkService.deleteNetworkAccountV2(
      requestor_id,
      network_id
    );
    thunk.dispatch(markSearchResultRemoved(response));
    return response;
  }, thunk)
);

export const addProjectToNetwork = createAsyncThunk<
  NetworkAccount,
  { accountId: string; data: NetworkProject; networkId: string }
>("business/network/project/add", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      BusinessAccountNetworkService.addProjectToNetwork(args.data, args.accountId, args.networkId),
    thunk
  )
);

export const removeProjectFromNetwork = createAsyncThunk<
  NetworkAccount,
  { accountId: string; networkId: string; projectId: string }
>("business/network/project/remove", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      BusinessAccountNetworkService.removeProjectFromNetworkV2(
        args.accountId,
        args.networkId,
        args.projectId
      ),
    thunk
  )
);

export const getPayees = createAsyncThunk<NetworkAccount[], string, {}>(
  "business/payee/get",
  async (id) => await BusinessAccountNetworkService.getNetwork(id),
  {
    condition: (arg, { getState }) => (getState() as RootState).payee.payees?.loading === false,
  }
);

export const getNetworkAccount = createAsyncThunk<
  NetworkAccount,
  { businessId: string; networkId: string }
>("business/network/get", async (args, thunk) => {
  try {
    return await BusinessAccountNetworkService.getNetworkAccount(args.businessId, args.networkId);
  } catch (e) {
    const action = e.status === 422 ? [...e.body.validation_errors] : e.body;
    thunk.dispatch(e.body);
    throw thunk.rejectWithValue(action);
  }
});

export const clearRecipientAccount = createAction("business/network/get/clear");
export const clearRemoveResponse = createAction("business/payee/delete/clear");

export const selectPayeeState = (state: RootState) => state.payee.payees;
export const selectRecipientAccountState = (state: RootState) => state.payee.recipientAccount;
export const selectRemoveResponse = (state: RootState) => state.payee.delete;
export const selectPayeeByProjectId = createSelector(
  [
    (state: RootState) => state.payee.payees.data,
    (_state: RootState, projectId: string) => projectId,
  ],
  (payees, projectId) => payees.filter((payee) => payee.projects?.some((p) => p.id === projectId))
);
export const makeSelectPayeeByProjectId = (id: string) => (state: RootState) =>
  selectPayeeByProjectId(state, id);

const initialState = {
  payees: createInitialState<NetworkAccount[]>([]),
  recipientAccount: createInitialState<NetworkAccount>(), // this is the network account that we want to pay to
  delete: createInitialState<NetworkAccount>(),
};
const payeeSlice = createSlice({
  name: "payee",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPayee.pending, (state) => {
      state.payees.error = null;
      state.payees.loading = true;
    });
    builder.addCase(createPayee.fulfilled, (state, action) => {
      state.payees.data = [...state.payees.data, action.payload];
      state.payees.loading = false;
      state.payees.error = null;
      state.payees.empty = state.payees.data.length === 0;
    });
    builder.addCase(createPayee.rejected, (state, action) => {
      state.payees.error = action.payload;
      state.payees.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(updateNetworkAccount.pending, (state) => {
      state.payees.error = null;
      state.payees.loading = true;
    });
    builder.addCase(updateNetworkAccount.fulfilled, (state, action) => {
      state.payees.error = null;
      state.payees.loading = false;
      const idx = state.payees.data.findIndex((item) => item.id === action.payload.id);
      if (idx > -1) {
        state.payees.data.splice(idx, 1, action.payload);
      }

      state.payees.empty = state.payees.data.length === 0;
    });
    builder.addCase(updateNetworkAccount.rejected, (state, action) => {
      state.payees.error = action.payload;
      state.payees.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(updateNetworkAccountLocal.fulfilled, (state, action) => {
      const idx = state.payees.data.findIndex((item) => item.id === action.payload.id);
      if (idx > -1) {
        state.payees.data.splice(idx, 1, action.payload);
      }
    });

    builder.addCase(removeNetworkAccount.pending, (state) => pending(state.delete));

    builder.addCase(removeNetworkAccount.fulfilled, (state, action) => {
      state.delete.loading = false;
      const idx = state.payees.data.findIndex((item) => item.id === action.payload.id);
      if (idx > -1) {
        state.payees.data.splice(idx, 1);
      }
      state.payees.empty = state.payees.data.length === 0;
    });

    builder.addCase(removeNetworkAccount.rejected, (state, action) => error(state.delete, action));

    builder.addCase(addProjectToNetwork.pending, (state) => {
      state.payees.error = null;
      state.payees.loading = true;
    });
    builder.addCase(addProjectToNetwork.fulfilled, (state, action) => {
      state.payees.loading = false;
      const idx = state.payees.data.findIndex((payee) => payee.id === action.payload.id);
      if (idx > -1) {
        state.payees.data.splice(idx, 1, action.payload);
      }
    });
    builder.addCase(addProjectToNetwork.rejected, (state, action) => {
      state.payees.loading = false;
      state.payees.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(removeProjectFromNetwork.pending, (state) => {
      state.payees.error = null;
      state.payees.loading = true;
    });
    builder.addCase(removeProjectFromNetwork.fulfilled, (state, action) => {
      state.payees.loading = false;

      // action.payload.id is the id of the deleted project
      const payee = state.payees.data.find((payee) => payee.id === action.meta.arg.networkId);
      payee.projects = payee?.projects.filter((project) => project.id !== action.payload.id);
      const idx = state.payees.data.findIndex((item) => item.id === payee?.id);
      if (idx > -1) {
        state.payees.data.splice(idx, 1, payee);
      }
    });
    builder.addCase(removeProjectFromNetwork.rejected, (state, action) => {
      state.payees.loading = false;
      state.payees.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(clearRemoveResponse, (state) => {
      state.delete.error = null;
      state.delete.loading = false;
    });

    builder.addCase(getPayees.pending, (state) => {
      state.payees.loading = true;
    });
    builder.addCase(getPayees.fulfilled, (state, action) => {
      state.payees.loading = false;
      state.payees.data = action.payload;
      state.payees.empty = action.payload.length === 0;
    });
    builder.addCase(getPayees.rejected, (state, action) => {
      state.payees.loading = false;
      state.payees.error = action.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(getNetworkAccount.pending, (state) => {
      state.recipientAccount.loading = true;
      state.recipientAccount.empty = true;
      state.recipientAccount.error = null;
    });
    builder.addCase(getNetworkAccount.fulfilled, (state, action) => {
      state.recipientAccount.loading = false;
      state.recipientAccount.empty = false;
      state.recipientAccount.data = action.payload;
    });
    builder.addCase(getNetworkAccount.rejected, (state, action) => {
      state.recipientAccount.loading = false;
      state.recipientAccount.error = action.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(clearRecipientAccount, (state) => {
      state.recipientAccount.data = null;
      state.recipientAccount.error = null;
      state.recipientAccount.loading = false;
      state.recipientAccount.empty = true;
    });

    builder.addCase(resetStore, () => ({ ...initialState }));
  },
});

export default payeeSlice.reducer;
