/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkFleetCardRequest } from '../models/BulkFleetCardRequest';
import type { BulkFleetCards } from '../models/BulkFleetCards';
import type { FleetAccount } from '../models/FleetAccount';
import type { FleetAccountBalance } from '../models/FleetAccountBalance';
import type { FleetActiveCardCountResponse } from '../models/FleetActiveCardCountResponse';
import type { FleetBusinessAccount } from '../models/FleetBusinessAccount';
import type { FleetCard } from '../models/FleetCard';
import type { FleetCardRequest } from '../models/FleetCardRequest';
import type { FleetCardResponse } from '../models/FleetCardResponse';
import type { FleetCardStatusUpdateRequest } from '../models/FleetCardStatusUpdateRequest';
import type { FleetCycleDateResponse } from '../models/FleetCycleDateResponse';
import type { FleetDisputeLinkResponse } from '../models/FleetDisputeLinkResponse';
import type { FleetInvoicePayRequest } from '../models/FleetInvoicePayRequest';
import type { FleetPaymentResponse } from '../models/FleetPaymentResponse';
import type { FleetPinRequest } from '../models/FleetPinRequest';
import type { FleetSharedAuthProfileRanges } from '../models/FleetSharedAuthProfileRanges';
import type { FleetVehicle } from '../models/FleetVehicle';
import type { MockFleetAuth } from '../models/MockFleetAuth';
import type { MockFleetInvoice } from '../models/MockFleetInvoice';
import type { MockFleetTransaction } from '../models/MockFleetTransaction';
import type { PaymentHistoryResponse } from '../models/PaymentHistoryResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FleetService {
    /**
     * Gets the fleet account details
     * @param id ID of the business account
     * @returns FleetAccount OK
     * @throws ApiError
     */
    public static getFleetAccount(
        id: string,
    ): CancelablePromise<FleetAccount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/fleet/account',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets the account balance for a fleet account
     * @param id ID of the business account
     * @param mockNoinvoice Simulate no invoice available for fleet account
     * @returns FleetAccountBalance OK
     * @throws ApiError
     */
    public static getFleetAccountBalance(
        id: string,
        mockNoinvoice?: number,
    ): CancelablePromise<FleetAccountBalance> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/fleet/account-balance',
            path: {
                'id': id,
            },
            query: {
                'mock-noinvoice': mockNoinvoice,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets all fleet cards for a specific business.
     * @param id ID of the business account
     * @param limit Maximum number of fleet cards to return
     * @param page Page to view, page size is based on limit param
     * @param sort field to sort results by, include multiple times for multiple sort directions. Valid fields are: vehicle.description, maskedcardnumber, lastupdatedat
     * @param direction direction in which to sort fields by, include one for each field, otherwise defaults to ascending
     * @param status Comma separated list of statuses to filter cards by.  Valid values are: active, locked, suspended, canceled, ordered, pending
     * @returns FleetCardResponse OK
     * @throws ApiError
     */
    public static getAllFleetCards(
        id: string,
        limit?: number,
        page?: number,
        sort?: string,
        direction?: string,
        status?: string,
    ): CancelablePromise<FleetCardResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/fleet/card',
            path: {
                'id': id,
            },
            query: {
                'limit': limit,
                'page': page,
                'sort': sort,
                'direction': direction,
                'status': status,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Creates a fleet card
     * @param data Fleet card to create
     * @param id ID of the business account
     * @returns FleetCard OK
     * @throws ApiError
     */
    public static postFleetCard(
        data: FleetCardRequest,
        id: string,
    ): CancelablePromise<FleetCard> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/fleet/card',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets a count of active Fleet cards and the cost per month
     * @param id ID of the business account
     * @returns FleetActiveCardCountResponse OK
     * @throws ApiError
     */
    public static getActiveFleetCardCount(
        id: string,
    ): CancelablePromise<FleetActiveCardCountResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/fleet/card/active-count',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets a specific Fleet card
     * @param id ID of the business account
     * @param cid ID of the Fleet card to fetch
     * @returns FleetCard OK
     * @throws ApiError
     */
    public static getFleetCard(
        id: string,
        cid: string,
    ): CancelablePromise<FleetCard> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/fleet/card/{cid}',
            path: {
                'id': id,
                'cid': cid,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Updates a fleet card
     * @param data Fleet card to update
     * @param id ID of the business account
     * @param cid ID of the Fleet card to update
     * @returns FleetCard OK
     * @throws ApiError
     */
    public static putFleetCard(
        data: FleetCardRequest,
        id: string,
        cid: string,
    ): CancelablePromise<FleetCard> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/fleet/card/{cid}',
            path: {
                'id': id,
                'cid': cid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Updates a fleet card status
     * @param data Fleet card status update
     * @param id ID of the business account
     * @param cid ID of the Fleet card to update
     * @returns FleetCard OK
     * @throws ApiError
     */
    public static putFleetCardStatus(
        data: FleetCardStatusUpdateRequest,
        id: string,
        cid: string,
    ): CancelablePromise<FleetCard> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/fleet/card/{cid}/status',
            path: {
                'id': id,
                'cid': cid,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Creates multiple fleet cards
     * @param data Fleet cards to create
     * @param id ID of the business account
     * @returns BulkFleetCards OK
     * @throws ApiError
     */
    public static postBulkFleetCards(
        data: BulkFleetCardRequest,
        id: string,
    ): CancelablePromise<BulkFleetCards> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/fleet/cards',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Generates an external link to dispute fleet transactions
     * @param id ID of the business account
     * @returns FleetDisputeLinkResponse OK
     * @throws ApiError
     */
    public static postFleetDisputeLink(
        id: string,
    ): CancelablePromise<FleetDisputeLinkResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/fleet/dispute/link',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets the cycle dates for a fleet business
     * @param id ID of the business account
     * @returns FleetCycleDateResponse OK
     * @throws ApiError
     */
    public static getFleetInvoiceCycleDates(
        id: string,
    ): CancelablePromise<FleetCycleDateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/fleet/invoice/cycle-dates',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Initiate the Fleet onboarding process
     * @param data Business account to onboard with Fleet
     * @param id ID of the business account
     * @returns FleetBusinessAccount OK
     * @throws ApiError
     */
    public static postFleetOnboard(
        data: FleetBusinessAccount,
        id: string,
    ): CancelablePromise<FleetBusinessAccount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/business-account/{id}/fleet/onboard',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets the universal PIN for a fleet account
     * @param id ID of the business account
     * @returns FleetPinRequest OK
     * @throws ApiError
     */
    public static getFleetPin(
        id: string,
    ): CancelablePromise<FleetPinRequest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/business-account/{id}/fleet/pin',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Updates a PIN for a fleet account
     * @param data Fleet PIN to update
     * @param id ID of the business account
     * @returns any OK
     * @throws ApiError
     */
    public static putFleetPin(
        data: FleetPinRequest,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/business-account/{id}/fleet/pin',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Processes DCA magic link requests
     * @returns void
     * @throws ApiError
     */
    public static processDca(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dca',
            errors: {
                302: `Found`,
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets allowable shared auth profile ranges
     * @returns FleetSharedAuthProfileRanges OK
     * @throws ApiError
     */
    public static getFleetSharedAuthProfileRanges(): CancelablePromise<FleetSharedAuthProfileRanges> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/fleet/authprofile-shared/ranges',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Deletes a mock fleet account
     * @param id ID of the mock fleet account
     * @returns any OK
     * @throws ApiError
     */
    public static deleteMockFleetAccount(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/mock/business-account/{id}/fleet',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Creates a mock fleet auth
     * @param data Fleet auth to create
     * @param id ID of the business account
     * @returns any OK
     * @throws ApiError
     */
    public static postMockFleetAuth(
        data: MockFleetAuth,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/mock/business-account/{id}/fleet/auth',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Updates a fleet card status to active (mock)
     * @param id ID of the business account
     * @param cid ID of the Fleet card to update
     * @returns FleetCard OK
     * @throws ApiError
     */
    public static putFleetCardStatusActivate(
        id: string,
        cid: string,
    ): CancelablePromise<FleetCard> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/mock/business-account/{id}/fleet/card/{cid}/activate',
            path: {
                'id': id,
                'cid': cid,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Creates a mock fleet invoice
     * @param data Fleet invoice to create
     * @param id ID of the business account
     * @returns any OK
     * @throws ApiError
     */
    public static postMockFleetInvoice(
        data: MockFleetInvoice,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/mock/business-account/{id}/fleet/invoice',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Creates a mock fleet invoice
     * @param id ID of the business account
     * @param jobs Jobs to run
     * @returns any OK
     * @throws ApiError
     */
    public static postMockFleetJobs(
        id: string,
        jobs?: '"find-fleet-account"' | '"get-fleet-account-status"' | '"card-retry"' | '"card-status"' | '"card-submit"' | '"get-invoice"',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/mock/business-account/{id}/fleet/jobs',
            path: {
                'id': id,
            },
            query: {
                'jobs': jobs,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Creates a mock fleet transaction
     * @param data Fleet transaction to create
     * @param id ID of the business account
     * @returns any OK
     * @throws ApiError
     */
    public static postMockFleetTransaction(
        data: MockFleetTransaction,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/mock/business-account/{id}/fleet/transaction',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets vehicle information using a VIN
     * @param vin VIN of the vehicle
     * @returns FleetVehicle OK
     * @throws ApiError
     */
    public static getVehicleFromVin(
        vin: string,
    ): CancelablePromise<FleetVehicle> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vin/{vin}',
            path: {
                'vin': vin,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Gets all fleet payments for a specific business.
     * @param id ID of the business account to fetch the payments of
     * @param limit Maximum number of payments to return
     * @param page Page to view, page size is based on limit param
     * @param sort field to sort results by, include multiple times for multiple sort directions. Valid fields are: date, posted_date, amount, payor.id, payee.id, payor.name, payee.name, id, status
     * @param direction direction in which to sort fields by, include one for each field, otherwise defaults to ascending
     * @param methods comma delimited list of one or more payment methods. Valid values are: check_paper, check_digital, ach, flumepay, card
     * @param statuses comma delimited list of one or more payment statuses. Valid values are: created, issued, in_progress, funding, complete, settled, pending-settled, error, rejected, returned, void, paid, refused, canceled
     * @param amounts amount range as comma delimited list of one or two amounts
     * @param dates payment date range as comma delimited list of one or two dates
     * @param postedDates payment posted date range as comma delimited list of one or two dates
     * @returns PaymentHistoryResponse OK
     * @throws ApiError
     */
    public static getFleetPaymentHistory(
        id: string,
        limit?: number,
        page?: number,
        sort?: string,
        direction?: string,
        methods?: string,
        statuses?: string,
        amounts?: string,
        dates?: string,
        postedDates?: string,
    ): CancelablePromise<PaymentHistoryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/business-account/{id}/fleet/invoice',
            path: {
                'id': id,
            },
            query: {
                'limit': limit,
                'page': page,
                'sort': sort,
                'direction': direction,
                'methods': methods,
                'statuses': statuses,
                'amounts': amounts,
                'dates': dates,
                'posted_dates': postedDates,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Create a fleet payment
     * @param data Create a fleet payment
     * @param id ID of the business account
     * @returns FleetPaymentResponse OK
     * @throws ApiError
     */
    public static payFleet(
        data: FleetInvoicePayRequest,
        id: string,
    ): CancelablePromise<FleetPaymentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/business-account/{id}/fleet/pay',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }
}
