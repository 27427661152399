import { isProd } from "flume/config";
import LoadingSpinner from "../../component/lottie/LoadingSpinner";
import "./app-loading.scss";

export const AppLoading = ({ source }: { source?: string }) => {
  return (
    <div className="app-loading">
      {source && !isProd() && <h1>{source}</h1>}
      <LoadingSpinner width="100%" height="100%" />
    </div>
  );
};
