import { DateTime } from "luxon";
import { Address } from "../generated/openapi";
import { DecimalNum } from "../generated/openapi";

export interface CurrencyFormatOptions {
  normalized?: boolean;
  showCents?: boolean;
  locale?: string;
  currency?: string;
  fractionDigits?: number;
}

export enum DateFormat {
  DATE = "MMM dd, yyyy",
  DATETIME = "MMM dd, yyyy @ hh:mm a",
  MDY = "MM/dd/yy",
  MDYYYY = "MM/dd/yyyy",
  NUMERICAL_DATE = "yyyy-MM-dd",
  TEXTFIELD = "yyyy-MM-dd'T'HH:mm",
  YEARLESS_DATE = "MMM dd",
  UTC = "yyyy-MM-dd'T'HH:mm:ssZZ",
  UTC_LOCAL = "yyyy-MM-dd'T'HH:mm:ss ZZZ",
  WRITTEN_DATE = "MMMM d, yyyy",
  MONTH_DAY_TIME = "MMM d, h:mm a",
  FILENAME_TIMESTAMP = "yyyy-MM-dd_HH.mm.ss",
  TIME = "hh:mm a",
  MONTH_YEAR = "MM/yy",
}

export const formatAddress = (
  address: Address,
  options: { includeCommas?: boolean; showCountry?: boolean } = {}
): string => {
  if (!address) return "";

  const formatOptions = {
    includeCommas: false,
    showCountry: false,
    ...options,
  };

  const street = `${address.line_1 || ""} ${address.line_2 || ""}`.trim();
  const commaJoinedSections = [
    ...(street ? [street] : []),
    ...(address.city ? [address.city] : []),
    ...(address.state ? [address.state] : []),
  ].join(formatOptions.includeCommas ? ", " : " ");

  return [
    ...(commaJoinedSections ? [commaJoinedSections] : []),
    ...(address.zip ? [address.zip] : []),
    ...(address.country_code && formatOptions.showCountry ? [address.country_code] : []),
  ].join(" ");
};

export const formatCurrency = (
  amount: number,
  {
    locale = "en-US",
    currency = "USD",
    normalized = false,
    showCents = true,
    fractionDigits = 2,
  }: CurrencyFormatOptions = {}
): string => {
  let num = amount || 0;
  num = normalized ? num / 100 : num;
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: showCents === true ? fractionDigits : 0,
  });

  return formatter.format(num);
};

export const formatDate = (date: Date, format: string) => {
  switch (format) {
    case DateFormat.UTC:
      return DateTime.fromISO(date.toISOString(), { setZone: true }).toFormat(DateFormat.UTC);
    case DateFormat.UTC_LOCAL:
      return DateTime.local(date.getFullYear(), date.getMonth() + 1, date.getDate()).toString();
    default:
      return DateTime.fromISO(date.toISOString()).toFormat(format);
  }
};

export const formatDateFromISO = (iso: string, format: string, preserveZone = false) => {
  const date = DateTime.fromISO(iso, { setZone: preserveZone });
  return date.toFormat(format);
};

/**
 * Converts a date string from one format to another.
 * @param dateStr the date string
 * @param initialFormat the format of the given date string.
 * @param targetFormat the format to change the date string to.
 * @returns the date in the target format. Empty string if not able to parse date.
 */
export const convertDateFormat = (dateStr: string, initialFormat: string, targetFormat: string) => {
  const date = DateTime.fromFormat(dateStr, initialFormat, { setZone: true });
  return date.invalidReason ? "" : date.toFormat(targetFormat);
};

export const formatWithHyphens = (base: string, indices: number[]) => {
  let modified = base.replace(/-/g, "");
  for (const i of indices) {
    if (modified.length <= i) break;
    modified = `${modified.slice(0, i)}-${modified.slice(i)}`;
  }
  return modified;
};

export const normalizeCurrency = (value: string) => Math.round(parseFloat(value) * 100) || 0;

/**
 *
 * @param lastActiveIso this is a string date in ISO format (eg. 2023-02-01T22:29:42.177Z)
 * @returns amount of time elapsed since lastActiveIso
 */
export const formatTimeSince = (lastActiveIso: string): string => {
  const lastActive = DateTime.fromISO(lastActiveIso);
  let currentTime = lastActive.zoneName
    ? DateTime.local().setZone(lastActive.zoneName)
    : DateTime.local();

  const sinceLastActive = currentTime
    .diff(lastActive, ["years", "months", "days", "hours", "minutes", "seconds"])
    .toObject();

  // iterate through the sinceLastActive object and return the first non-zero value
  for (const key in sinceLastActive) {
    if (sinceLastActive[key] !== 0) {
      if (key === "seconds" && sinceLastActive[key] < 60) return "Just now";
      if (sinceLastActive[key] === 1) return `${sinceLastActive[key]} ${key.slice(0, -1)} ago`;
      return `${sinceLastActive[key]} ${key} ago`;
    }
  }
};

export const formatTimeLeft = (time: number) => {
  // Ensure time is not negative
  time = Math.max(0, time);

  const minutes = Math.floor(time / 60000);
  const seconds = ((time % 60000) / 1000).toFixed(0);
  return `${minutes}:${parseInt(seconds) < 10 ? "0" : ""}${seconds}`;
};

/**
 * Formats a number by moving decimal by specified number of places.
 * Optionally formats the number as currency.
 *
 * @param {DecimalNum} decimalNum - The number to be formatted, including its precision.
 * @param {Object} [options] - Optional formatting options.
 * @param {boolean} [options.isCurrency] - Whether to format the number as currency (formatting as currency displays at least two fractional digits because of the case where we use 1 precision but still need to add a zero at the end so we don't have currency with 1 fractional digit).
 * @returns {string} The formatted number as a string, optionally as a currency string.
 */
export const formatDecimal = (
  decimalNum: DecimalNum,
  options: { isCurrency?: boolean } = {}
): string => {
  if (!decimalNum) return "";
  const num = decimalNum.value / Math.pow(10, decimalNum.precision);
  return options.isCurrency
    ? formatCurrency(num, { fractionDigits: Math.max(2, decimalNum.precision) })
    : String(num);
};
