import { useOktaAuth } from "@okta/okta-react";

export const RequireAuth = ({
  children,
  onAuthRequired,
}: {
  children: React.ReactNode;
  onAuthRequired: () => void;
}) => {
  const { authState } = useOktaAuth();
  if (!authState?.isAuthenticated) {
    onAuthRequired();
    return null;
  }

  return children;
};
