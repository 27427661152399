import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { loadCurrentUser } from "../user/userSlice";
import { logRejectedThunk } from "../../sentry";
import { RootState } from "../../store";
import {
  BusinessAccount,
  BusinessAccountCreateRequest,
  FlumeBalance,
  LinkToken,
  BusinessAccountService,
  BusinessAccountOnboardingService,
  PlaidService,
  ErpService,
  DeleteResponse,
  IDVLinkToken,
  DocumentVerificationRequest,
  AccountVerificationService,
} from "../../generated/openapi";
import { BeneficialOwner } from "../../generated/openapi/models/BeneficialOwner";
import { returnWithErrorWrap } from "../../store/error";
import { logEvent } from "../../firebase";
import {
  update,
  remove,
  loadingCondition,
  pending,
  error,
  fulfilled,
  createInitialState,
} from "../../store/reducer";
import { resetStore } from "../../store/slice";

export const createBusinessAccount = createAsyncThunk<
  BusinessAccount,
  { account: BusinessAccountCreateRequest },
  {}
>("business/create", async (arg, thunk) => {
  return returnWithErrorWrap(
    async () => await BusinessAccountService.createBusinessAccount(arg.account),
    thunk
  );
});

export const updateBusinessAccount = createAsyncThunk<BusinessAccount, any, {}>(
  "business/update",
  async (arg, thunk) => {
    return returnWithErrorWrap(
      async () => await BusinessAccountService.updateBusinessAccount(arg.account, arg.id),
      thunk
    );
  }
);

export const completeBusinessAccount = createAsyncThunk<
  BusinessAccount,
  { iovation_blackbox: string; id: string },
  {}
>("business/complete", async (arg, thunk) => {
  const response = await BusinessAccountOnboardingService.completeBusinessAccount(arg, arg.id);
  await thunk.dispatch(loadCurrentUser());
  return response;
});

export const getBusinessAccount = createAsyncThunk<BusinessAccount, string, {}>(
  "business/get",
  async (arg, thunk) => {
    return returnWithErrorWrap(() => BusinessAccountService.getBusinessAccount(arg), thunk);
  },
  {
    condition: (arg, { getState }) =>
      loadingCondition((getState() as RootState).business.businessAccounts),
  }
);

export const nullBusinessAccount = createAsyncThunk<BusinessAccount[], void, {}>(
  "business/null",
  async (arg, thunk) => {
    return [];
  }
);

export const createPlaidLinkToken = createAsyncThunk<LinkToken, string, {}>(
  "business/plaid/linkToken",
  async (arg, thunk) => {
    return PlaidService.createPlaidLinkToken(arg, "web");
  }
);

export const createPlaidIDVToken = createAsyncThunk<IDVLinkToken, { businessAccountId: string }>(
  "business/plaid/idvToken",
  async (args) => PlaidService.createPlaidIdvLinkToken(args.businessAccountId, "web")
);

export const getVerificationRequests = createAsyncThunk<
  DocumentVerificationRequest[],
  { businessAccountId: string }
>("business/verify/getAll", async (arg, thunk) =>
  returnWithErrorWrap(
    () => AccountVerificationService.getAllDocumentVerificationRequests(arg.businessAccountId),
    thunk
  )
);
export const postVerificationDocument = createAsyncThunk<
  DocumentVerificationRequest,
  { businessAccountId: string; documentRequestId: string; file: Blob; documentType: string }
>("business/verify/postDocument", async (arg, thunk) =>
  returnWithErrorWrap(
    () =>
      AccountVerificationService.postVerificationDocument(
        arg.businessAccountId,
        arg.documentRequestId,
        arg.file,
        arg.documentType
      ),
    thunk
  )
);

export const createBeneficialOwner = createAsyncThunk<
  BeneficialOwner,
  { accountId: string; owner: BeneficialOwner },
  {}
>("business/beneficialOwner/create", async (arg, thunk) => {
  return returnWithErrorWrap(
    async () =>
      await BusinessAccountOnboardingService.createBeneficialOwner(arg.accountId, arg.owner),
    thunk
  );
});
export const getBeneficialOwners = createAsyncThunk<BeneficialOwner[], string, {}>(
  "business/beneficialOwner/get",
  async (accountId, thunk) => {
    return returnWithErrorWrap(
      async () => await BusinessAccountOnboardingService.getBeneficialOwners(accountId),
      thunk
    );
  }
);
export const updateBeneficialOwner = createAsyncThunk<
  BeneficialOwner,
  { accountId: string; owner: BeneficialOwner },
  {}
>("business/beneficialOwner/update", async (arg, thunk) => {
  return returnWithErrorWrap(
    async () =>
      await BusinessAccountOnboardingService.updateBeneficialOwner(
        arg.accountId,
        arg.owner.id,
        arg.owner
      ),
    thunk
  );
});
export const deleteBeneficialOwner = createAsyncThunk<
  DeleteResponse,
  { accountId: string; beneficialOwnerId: string },
  {}
>("business/beneficialOwner/delete", async (arg, thunk) => {
  return returnWithErrorWrap(
    async () =>
      await BusinessAccountOnboardingService.deleteBeneficialOwnerV2(
        arg.accountId,
        arg.beneficialOwnerId
      ),
    thunk
  );
});

export const getAvailableBalance = createAsyncThunk<FlumeBalance, string, {}>(
  "business/availableBalance/get",
  async (arg, thunk) => {
    return await BusinessAccountService.getBusinessAccountBalance(arg);
  },
  {
    condition: (arg, { getState }) => {
      const {
        business: { availableBalance },
      } = getState() as any;
      return availableBalance.loading === false;
    },
  }
);

export const updateErpIntegration = createAsyncThunk<
  BusinessAccount,
  { id: string; integrationId: string },
  {}
>("business/integration/linkpending", async (arg, thunk) => {
  return returnWithErrorWrap(
    () => ErpService.updateLinkedPending(arg?.id, arg?.integrationId),
    thunk
  );
});

export const claimBusiness = createAsyncThunk<any, { accountId: string; inviteCode: string }>(
  "business/claim",
  async (arg, thunk) =>
    returnWithErrorWrap(
      () => BusinessAccountService.claimBusiness(arg.accountId, arg.inviteCode),
      thunk
    )
);

export const updateFlumeTag = createAsyncThunk<
  BusinessAccount,
  { accountId: string; flumeTag: string },
  {}
>("business/flumetag/update", async (arg, thunk) => {
  return returnWithErrorWrap(
    () => BusinessAccountService.putFlumeTag(arg.accountId, { flume_tag: arg.flumeTag }),
    thunk
  );
});

export const selectPendingBusinessAccount = (state: RootState): BusinessAccount =>
  state.business.businessAccounts.data.find(
    (account) =>
      account.status === BusinessAccount.status.GATHER_INFORMATION ||
      account.status === BusinessAccount.status.ONBOARDING
  ) || ({} as BusinessAccount);
export const selectCheckingAccounts = (state: RootState) =>
  state.business.bankAccounts.filter((acct) => acct.subtype === "checking");
export const selectBusinessAccountsState = (state: RootState) => state.business.businessAccounts;
export const selectBusinessAccounts = (state: RootState) => state.business.businessAccounts.data;
export const selectPlaidLinkToken = (state: RootState) => state.business.linkToken;
export const selectPlaidIDVToken = (state: RootState) => state.business.idvToken;
export const selectVerificationRequestsState = (state: RootState) => state.business.verifyRequests;
export const selectBeneficialOwnersState = (state: RootState) => state.business.beneficialOwners;
export const selectBeneficialOwners = (state: RootState) => state.business.beneficialOwners.data;
export const selectFlumeBalance = (state: RootState) => state.business.availableBalance.data;
export const selectFlumeBalanceState = (state: RootState) => state.business.availableBalance;
export const selectHasBusinessAccountBeenFetched = (state: RootState): boolean => {
  return (
    state.business.businessAccounts.loading ||
    state.business.businessAccounts.empty ||
    state.business.businessAccounts.data.length > 0
  );
};
export const selectBusinessAccount = createSelector(
  [
    (state: RootState) => state.business.businessAccounts.data,
    (_state: RootState, id: string) => id,
  ],
  (accounts, id) => accounts.find((acct) => acct?.id === id)
);
export const makeSelectBusinessAccount = (id: string) => {
  return (state: RootState) => selectBusinessAccount(state, id);
};

const initialState = {
  businessAccounts: createInitialState<BusinessAccount[]>([]),
  beneficialOwners: createInitialState<BeneficialOwner[]>([]),
  availableBalance: createInitialState(),
  bankAccounts: [],
  linkToken: null,
  idvToken: createInitialState(),
  verifyRequests: createInitialState([]),
};

const businessSlice = createSlice({
  name: "business",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createBusinessAccount.pending, (state) => {
      state.businessAccounts.loading = true;
      state.businessAccounts.error = null;
    });
    builder.addCase(createBusinessAccount.fulfilled, (state, action) => {
      state.businessAccounts.loading = false;
      state.businessAccounts.data = [...state.businessAccounts.data, action.payload];
    });
    builder.addCase(createBusinessAccount.rejected, (state, action) => {
      state.businessAccounts.loading = false;
      state.businessAccounts.error = action.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(updateBusinessAccount.pending, (state) => {
      state.businessAccounts.loading = true;
      state.businessAccounts.error = null;
    });
    builder.addCase(updateBusinessAccount.fulfilled, (state, action) => {
      state.businessAccounts.loading = false;
      state.businessAccounts.data = [
        ...state.businessAccounts.data.filter((account) => account.id !== action.payload.id),
        action.payload,
      ];
    });
    builder.addCase(updateBusinessAccount.rejected, (state, action) => {
      state.businessAccounts.loading = false;
      state.businessAccounts.error = (action.payload as any)?.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(completeBusinessAccount.pending, (state) => {
      state.businessAccounts.loading = true;
      state.businessAccounts.error = null;
    });
    builder.addCase(completeBusinessAccount.fulfilled, (state, action) => {
      logEvent("onboard_finish", { name: action.payload.name });
      state.businessAccounts.loading = false;
      state.businessAccounts.data = [
        ...state.businessAccounts.data.filter((account) => account.id !== action.payload.id),
        action.payload,
      ];
    });
    builder.addCase(completeBusinessAccount.rejected, (state, action) => {
      state.businessAccounts.loading = false;
      state.businessAccounts.error = action.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(getBeneficialOwners.pending, (state) => {
      state.beneficialOwners.loading = true;
      state.beneficialOwners.error = null;
    });
    builder.addCase(getBeneficialOwners.fulfilled, (state, action) => {
      state.beneficialOwners.loading = false;
      state.beneficialOwners.data = action.payload || [];
      state.beneficialOwners.empty = state.beneficialOwners.data.length > 0;
    });
    builder.addCase(getBeneficialOwners.rejected, (state, action) => {
      state.beneficialOwners.loading = false;
      state.beneficialOwners.error = action.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(createBeneficialOwner.pending, (state) => {
      state.beneficialOwners.loading = true;
      state.beneficialOwners.error = null;
    });
    builder.addCase(createBeneficialOwner.fulfilled, (state, action) => {
      state.beneficialOwners.loading = false;
      state.beneficialOwners.data = [...state.beneficialOwners.data, action.payload];
      state.beneficialOwners.empty = state.beneficialOwners.data.length > 0;
    });
    builder.addCase(createBeneficialOwner.rejected, (state, action) => {
      state.beneficialOwners.loading = false;
      state.beneficialOwners.error = action.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(updateBeneficialOwner.pending, (state) => {
      state.beneficialOwners.loading = true;
      state.beneficialOwners.error = null;
    });
    builder.addCase(updateBeneficialOwner.fulfilled, (state, action) => {
      state.beneficialOwners.loading = false;
      state.beneficialOwners.data[
        state.beneficialOwners.data.findIndex((owner) => owner.id === action.payload.id)
      ] = action.payload;
      state.beneficialOwners.empty = state.beneficialOwners.data.length > 0;
    });
    builder.addCase(updateBeneficialOwner.rejected, (state, action) => {
      state.beneficialOwners.loading = false;
      state.beneficialOwners.error = action.error;
      logRejectedThunk(state, action);
    });
    builder.addCase(deleteBeneficialOwner.pending, (state) => pending(state.beneficialOwners));

    builder.addCase(deleteBeneficialOwner.fulfilled, (state, action) =>
      remove(state.beneficialOwners, action)
    );

    builder.addCase(deleteBeneficialOwner.rejected, (state, action) =>
      error(state.beneficialOwners, action)
    );
    builder.addCase(nullBusinessAccount.fulfilled, (state, action) => {
      fulfilled(state.businessAccounts, action);
    });
    builder.addCase(getBusinessAccount.pending, (state) => pending(state.businessAccounts));

    builder.addCase(getBusinessAccount.fulfilled, (state, action) => {
      update(state.businessAccounts, action);
    });

    builder.addCase(createPlaidLinkToken.fulfilled, (state, action) => {
      state.linkToken = action.payload.link_token;
    });

    builder.addCase(createPlaidIDVToken.pending, (state) => pending(state.idvToken));
    builder.addCase(createPlaidIDVToken.fulfilled, (state, action) =>
      fulfilled(state.idvToken, action)
    );
    builder.addCase(createPlaidIDVToken.rejected, (state, action) => error(state.idvToken, action));

    builder.addCase(getVerificationRequests.pending, (state) => pending(state.verifyRequests));
    builder.addCase(getVerificationRequests.fulfilled, (state, action) =>
      fulfilled(state.verifyRequests, action)
    );
    builder.addCase(getVerificationRequests.rejected, (state, action) =>
      error(state.verifyRequests, action)
    );

    //Available balance
    builder.addCase(getAvailableBalance.fulfilled, (state, action) => {
      state.availableBalance.data = action.payload;
      state.availableBalance.loading = false;
      state.availableBalance.empty = false;
    });

    builder.addCase(getAvailableBalance.pending, (state, action) => {
      state.availableBalance.loading = true;
      state.availableBalance.empty = false;
    });

    builder.addCase(getAvailableBalance.rejected, (state, action) => {
      state.availableBalance.error = action.error;
      state.availableBalance.empty = true;
      state.availableBalance.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(updateErpIntegration.fulfilled, (state, action) => {
      state.businessAccounts.loading = false;
      state.businessAccounts.data = [
        ...state.businessAccounts.data.filter((account) => account.id !== action.payload.id),
        action.payload,
      ];
      state.businessAccounts.error = null;
    });

    builder.addCase(updateErpIntegration.pending, (state, action) => {
      state.businessAccounts.loading = true;
    });

    builder.addCase(updateErpIntegration.rejected, (state, action) => {
      state.businessAccounts.loading = false;
      state.businessAccounts.error = action.payload;
    });

    builder.addCase(updateFlumeTag.pending, (state, action) => {
      state.businessAccounts.loading = true;
      state.businessAccounts.error = null;
    });

    builder.addCase(updateFlumeTag.fulfilled, (state, action) => {
      const idx = state.businessAccounts.data.findIndex((acct) => acct.id === action.payload.id);
      state.businessAccounts.loading = false;
      if (idx > -1) {
        state.businessAccounts.data.splice(idx, 1, action.payload);
      }
    });

    builder.addCase(updateFlumeTag.rejected, (state, action) => {
      state.businessAccounts.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(claimBusiness.rejected, logRejectedThunk);
    builder.addCase(getBusinessAccount.rejected, logRejectedThunk);
    builder.addCase(createPlaidLinkToken.rejected, logRejectedThunk);
    builder.addCase(resetStore, () => ({ ...initialState }));
  },
});

export default businessSlice.reducer;
