import { UserBusinessAccount } from "../../generated/openapi/models/UserBusinessAccount";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User, UserService, PlaidService, BusinessAccount } from "../../generated/openapi";
import { logRejectedThunk } from "../../sentry";
import { RootState } from "../../store";
import { PersonalInformationRequest } from "../../generated/openapi/models/PersonalInformationRequest";
import { returnWithErrorWrap } from "../../store/error";
import { getBusinessAccount, makeSelectBusinessAccount } from "../business/businessSlice";
import { logEvent } from "../../firebase";
import { resetStore } from "../../store/slice";
import { createInitialState } from "flume/store/reducer";
import { FleetAccountStatus } from "../fuel/type";

export const loadCurrentUser = createAsyncThunk<User, void, {}>(
  "user/load",
  async (arg, thunk) => {
    return await UserService.validate();
  },
  {
    condition: (arg, { getState }) => {
      const {
        user: { user },
      } = getState() as any;
      return user.loading === false;
    },
  }
);

export const acceptTerms: any = createAsyncThunk<User, void, {}>(
  "user/accept_terms",
  async (arg, thunk) => {
    return await UserService.acceptTerms();
  }
);

export const updatePersonalData = createAsyncThunk<User, PersonalInformationRequest, {}>(
  "user/personalData/update",
  async (arg, thunk) => {
    return returnWithErrorWrap(() => UserService.personalInformation(arg), thunk);
  }
);

export const sendAccessCode = createAsyncThunk<User, string, {}>(
  "user/accessCode",
  async (arg, thunk) => {
    return returnWithErrorWrap(() => UserService.valdiateInvitationCode(arg), thunk);
  }
);

export const setAccessToken = createAsyncThunk<User, { token: string; id: string }, {}>(
  "user/plaid/setToken",
  async (arg, thunk) => {
    const response = await PlaidService.setPlaidAccessToken({ public_token: arg.token }, arg.id);
    await thunk.dispatch(getBusinessAccount(arg.id));
    return response;
  }
);

export const selectCurrentUser = (state): User => state.user.user.data;
export const selectCurrentUserState = (state: RootState) => state.user.user;
export const selectPrimaryBusinessAccount = (state): UserBusinessAccount | null => {
  return state.user?.user?.data?.business_accounts
    ? state.user?.user?.data?.business_accounts[0]
    : null;
};

export const selectIsFleetAccountSuspended = (state: RootState) => {
  const userAccount = selectPrimaryBusinessAccount(state);
  const businessAccount: BusinessAccount = makeSelectBusinessAccount(
    userAccount?.business_account_id
  )(state);
  return (
    !businessAccount?.capabilities?.fleet_allow_card_orders &&
    businessAccount?.fleet?.account_status === FleetAccountStatus.SUSPENDED
  );
};
export const selectUserHasCompletedAccount = (state) =>
  state.user?.user?.data?.business_accounts?.some(
    (account: UserBusinessAccount) => account.status === "complete"
  );

export const selectUserHasCompletedOnboarding = (state) =>
  state.user.user?.data?.business_accounts?.some(
    (account: UserBusinessAccount) => account.status === "onboarding"
  );

export const selectUserIsUnauthorized = (state) => state.user.unauthorized;

export const selectHasPlaidToken = (state): boolean => state.user.plaidToken;
export const selectOwnedBusinessAccount = (state) =>
  state.user.user?.data?.business_accounts?.filter((acct) => acct.role !== "owner");
export const selectInProgressBusinessAccount = (state): UserBusinessAccount | null =>
  state.user.user?.data?.business_accounts?.find(
    (acct) => acct.status !== "complete" || acct.status !== "onboarding"
  );
export const selectHasUserBeenFetched = (state) =>
  state.user.user?.data !== null || state.user.user?.loading === true;
export const selectUserAccessCode = (state) => state.user.accessCode;
const userActionStarted = (state, action) => {
  state.user.loading = true;
};
const userActionFailed = (state, action) => {
  state.user.loading = false;
  state.user.error = action.error;
  logRejectedThunk(state, action);
};

const initialState = {
  user: createInitialState<User>(),
  personalData: createInitialState<any>(),
  plaidToken: false,
  unauthorized: false,
};
const userSlice = createSlice({
  name: "user",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadCurrentUser.pending, userActionStarted);
    builder.addCase(loadCurrentUser.rejected, (state, action) => {
      state.user.error = action.error;
      state.user.loading = false;

      if (action.error.message === "Forbidden" || action.error.message === "Unauthorized") {
        state.unauthorized = true;
      } else {
        logRejectedThunk(state, action);
      }
    });
    builder.addCase(loadCurrentUser.fulfilled, (state, action) => {
      state.user.data = action.payload;
      state.user.loading = false;
      state.user.error = null;
      state.user.fulfilled = Date.now();
    });

    builder.addCase(acceptTerms.pending, userActionStarted);

    builder.addCase(acceptTerms.fulfilled, (state, action) => {
      state.user.data = action.payload;
      state.user.loading = false;
      logEvent("onboard_start", action.payload);
    });
    builder.addCase(setAccessToken.pending, userActionStarted);
    builder.addCase(setAccessToken.fulfilled, (state, action) => {
      state.user.data = action.payload;
      state.plaidToken = true;
      state.user.loading = false;
    });
    builder.addCase(updatePersonalData.pending, userActionStarted);
    builder.addCase(updatePersonalData.fulfilled, (state, action) => {
      state.user.data = action.payload;
      state.user.loading = false;
    });
    builder.addCase(sendAccessCode.pending, userActionStarted);
    builder.addCase(sendAccessCode.fulfilled, (state, action) => {
      state.user.data = action.payload;
      state.user.loading = false;
    });

    builder.addCase(sendAccessCode.rejected, (state, action) => {
      state.user.error = action.payload;
      state.user.loading = false;
      logRejectedThunk(state, action);
    });
    builder.addCase(acceptTerms.rejected, userActionFailed);
    builder.addCase(updatePersonalData.rejected, userActionFailed);
    builder.addCase(setAccessToken.rejected, userActionFailed);

    builder.addCase(resetStore, () => ({ ...initialState }));
  },
});

export default userSlice.reducer;
