import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { lazy, Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Outlet,
  Routes,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";

import "./App.scss";

import { ExternalRedirect } from "./component/ExternalRedirect";
import { RequireAuth } from "./component/RequireAuth";
import { VersionWatcher } from "./component/VersionWatcher";
import { AppFallback } from "./component/error/AppFallback";
import { FlumeErrorBoundary } from "./component/error/FlumeErrorBoundary";
import { dateInputLocaleProps } from "./component/form/DateInputLocale";
import { theme } from "./component/theme/theme";
import { initializeDatadogRum } from "./datadog";
import { registerMetaPixel } from "./facebook";
import { FirebasePageTracker } from "./firebase";
import { initApi } from "./http/api-client";
import { getPath, getWildcardPath, routes } from "./http/route";
import { initializeI18n } from "./i18n/init";
import { MainLayout } from "./layout/main";
import { CenterLayout } from "./layout/main/CenterLayout";
import { OktaSignInWidgetController } from "./module/login";
import { StoreAccountType } from "./module/login/StoreAccountType";
import { AppLoading } from "./module/main/AppLoading";
import { ErrorNotFound } from "./module/main/ErrorNotFound";
import { InviteCodeObserver } from "./module/user/InviteCodeObserver";
import { oktaAuth } from "./okta";
import { initialize as initSentry, SentryRoute } from "./sentry";
import { store } from "./store";

registerMetaPixel();
initApi();
initializeI18n();
initializeDatadogRum();

const LazyMainApp = lazy(() => import("./module/main/MainApp"));
const LazyPaymentPortalRouter = lazy(() => import("./module/public/payment/router"));
const LazyEstimateReviewPortalControllerProvider = lazy(
  () => import("./module/public/invoice/EstimateReviewPortalController")
);
const LazyFileUploadPortalControllerProvider = lazy(() => import("./module/public/file"));
const LazyAcceptTerms = lazy(() => import("./module/user/AcceptTerms"));

const App = () => {
  const navigate = useNavigate();
  const onAuthHandler = () => {
    navigate(routes.login.path);
  };

  const restoreUrl = async (_, originalUrl) => {
    navigate(toRelativeUrl(originalUrl || getPath(routes.app), window.location.origin), {
      replace: true,
    });
  };

  const onAuthResume = async () => {
    navigate(routes.login.path);
  };

  useEffect(() => {
    initSentry({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    });
  }, []);

  // expose store when run in Cypress
  if (window.Cypress) {
    window["store"] = store;
  }

  return (
    <FlumeErrorBoundary fallback={AppFallback}>
      <Security oktaAuth={oktaAuth} onAuthRequired={onAuthHandler} restoreOriginalUri={restoreUrl}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider {...dateInputLocaleProps}>
              <VersionWatcher route={true} snack={true} />
              <FirebasePageTracker />

              <InviteCodeObserver />
              <Suspense fallback={<AppLoading />}>
                <Routes>
                  <SentryRoute path="/" element={<Navigate to={routes.login.path} replace />} />
                  {/* Auth */}
                  <SentryRoute path={routes.register.path} element={<AuthLayoutWrapper />}>
                    <SentryRoute index element={<OktaSignInWidgetController register />} />
                  </SentryRoute>
                  <SentryRoute path={routes.login.path} element={<AuthLayoutWrapper />}>
                    <SentryRoute index element={<OktaSignInWidgetController />} />
                    <SentryRoute
                      path={routes.login.callback.path}
                      element={<LoginCallback onAuthResume={onAuthResume} />}
                    />
                  </SentryRoute>
                  {/* Main app, authenticated */}
                  <SentryRoute
                    path={getWildcardPath(routes.app)}
                    element={
                      <RequireAuth onAuthRequired={onAuthHandler}>
                        <LazyMainApp />
                      </RequireAuth>
                    }
                  />
                  {/* Public portals */}
                  <SentryRoute
                    path={getPath(routes.estimatePortal.account.request, false)}
                    element={<LazyEstimateReviewPortalControllerProvider />}
                  />
                  <SentryRoute
                    path={getPath(routes.filePortal.account.request, false)}
                    element={<LazyFileUploadPortalControllerProvider />}
                  />
                  <SentryRoute
                    path={getWildcardPath(routes.payPortal)}
                    element={<LazyPaymentPortalRouter />}
                  />
                  {/* Miscellaneous */}
                  <SentryRoute path={routes.terms.path} element={<LazyAcceptTerms />} />
                  <SentryRoute path="*" element={<NoAuthLayoutWrappper />}>
                    <SentryRoute path="*" element={<ErrorNotFound className="py-0" dark />} />
                  </SentryRoute>
                  {/* Redirects */}
                  <SentryRoute
                    path={routes.fees.path}
                    element={<ExternalRedirect to="https://wexselect.com/fees" />}
                  />
                  <SentryRoute
                    path={routes.pricing.path}
                    element={<ExternalRedirect to="https://wexselect.com/pricing" />}
                  />
                  <SentryRoute
                    path={routes.promo.path}
                    element={<ExternalRedirect to="https://wexselect.com" />}
                  />
                  <SentryRoute
                    path={routes.support.path}
                    element={<ExternalRedirect to="https://wexselect.com/contact" />}
                  />
                </Routes>
              </Suspense>
            </LocalizationProvider>
          </ThemeProvider>
        </Provider>
      </Security>
    </FlumeErrorBoundary>
  );
};
export default App;

const NoAuthLayoutWrappper = () => (
  <CenterLayout>
    <Outlet />
  </CenterLayout>
);
const AuthLayoutWrapper = () => (
  <MainLayout>
    <>
      <StoreAccountType />
      <Outlet />
    </>
  </MainLayout>
);
