import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { Snackbar } from "@mui/material";
import config from "flume/config";
import { useI18n } from "flume/i18n";
import { logSentryError } from "flume/sentry";
import { startTransition, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "./button";
import { useInterval } from "./hook";
import { FlumeIcon } from "./icon";

export type VersionWatcherProps = {
  snack?: boolean;
  route?: boolean;
  interval?: number;
};
const defaultInterval = config.versionWatch?.interval || 1000 * 60 * 2; //uses config default or falls back to polling every 2 minutes
const versionPattern = /^\d{8}\.\d$/;

/**
 * VersionWatcher component monitors the application's version and prompts the user to refresh the page if a new version is detected.
 *
 * @param {boolean} [snack=true] - Determines if a snackbar notification should be shown when a new version is detected.
 * @param {boolean} [route=false] - If true, the page will automatically reload when a new version is detected and the route changes.
 * @param {number} [interval=defaultInterval] - The interval in milliseconds at which the version check is performed.
 *
 * @returns {JSX.Element | null} - Returns a JSX element if the snackbar is to be shown, otherwise null.
 */
export const VersionWatcher = ({
  snack = true,
  route = false,
  interval = defaultInterval,
}: VersionWatcherProps) => {
  const [isLatest, setIsLatest] = useState(true);
  const t = useI18n({ namespaces: ["component"], prefix: "oldVersion" });
  const location = useLocation();
  const [updateLater, setUpdateLater] = useState(false);

  const cancel = useInterval(
    useCallback(async () => {
      if (updateLater) {
        return;
      }
      const response = await fetch("/version.txt", { cache: "no-cache" });
      const buildVersion = await response.text();
      if (!versionPattern.test(buildVersion) || !versionPattern.test(config.version)) {
        logSentryError(
          `Invalid version pattern... build version ${buildVersion}, config version ${config.version}`
        );
        return;
      } else if (buildVersion === config.version) {
        setIsLatest(true);
      } else {
        startTransition(() => setIsLatest(false));
      }
    }, [updateLater]),
    interval
  );

  useEffect(() => {
    if (!isLatest && route && !updateLater) {
      window.location.reload();
    }
  }, [location]); //you only want this to fire when the location has changed, don't add the other dependencies.

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  const handleLater = useCallback(() => {
    setUpdateLater(true);
    cancel();
  }, [cancel]);

  useEffect(() => () => cancel(), [cancel]);
  if (updateLater || isLatest) {
    return null;
  }
  const action = (
    <>
      <Button
        size="sm"
        className="mr-2"
        rounded
        variant="secondary"
        theme="neutral"
        onClick={handleLater}
      >
        {t("ctaDecline")}
      </Button>
      <Button size="sm" rounded onClick={refreshPage}>
        {t("cta")}
      </Button>
    </>
  );
  const message = (
    <>
      <h5 className="font-weight-semibold">
        <FlumeIcon size="lg" icon={faCircleInfo} className="mr-2" />
        {t("title")}
      </h5>
      <p className="font-size-body-condensed text-muted font-weight-regular">{t("description")} </p>
    </>
  );

  return (
    snack && (
      <Snackbar
        variant="light"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!isLatest && !updateLater}
        message={message}
        action={action}
      />
    )
  );
};
